import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bemPrefix } from 'src/utils';
import { AppEventList, SimpleEventItem } from 'src/reducers/events';
import { eventNameToHuman } from 'src/utils/events';
import { InteractionType } from 'src/types/core';
import { OrganizationApp } from 'src/reducers/organizations';
import { doesInteractionSupportMultipleTargets } from 'src/interactions/newinteractions/interaction-utils';

import { Icon } from '../icons';

import './table-option.scss';
import { InputCheckbox } from '../input/input-checkbox';

const bem = bemPrefix('table-option');
const noop = () => null;

export type TableOptionItem = {
  app_ids: string[];
  label: string;
  key?: string;
};

export interface TableOptionProps<T extends TableOptionItem, S extends string> {
  apps?: OrganizationApp[];
  items?: T[];
  searchQuery?: string;
  tableOptionRef: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
  type: InteractionType;
  title: string;
  onMultipleSelectItem?: (selected: T) => void;
  isMultipleOptionDisabled?: (multipleOptions: T) => boolean;
  renderLabel?: (item: T) => React.ReactNode;
  renderSearchKey?: (item: T) => string;
  subsetOptions?: { name: S; label: React.ReactNode; className?: string }[];
  subsets?: Set<S>;
  subsetFilter?: (subsets: Set<S>, item: T) => boolean;
  onSubsetsChanged?: (subset: Set<S>) => void;
}

const APPS_PER_PAGE = 3;
const ITEMS_PER_LOAD = 5;

export const TableOption = <T extends TableOptionItem, S extends string>({
  apps = [],
  items = [],
  searchQuery,
  tableOptionRef,
  isOpen,
  type,
  title,
  onMultipleSelectItem = noop,
  isMultipleOptionDisabled = () => false,
  renderLabel = ({ label }) => label,
  renderSearchKey = ({ label }) => label,
  subsets,
  subsetOptions,
  subsetFilter,
  onSubsetsChanged = () => {},
}: TableOptionProps<T, S>) => {
  const [page, setPage] = useState(0);
  const [loadedCount, setLoadedCount] = useState(ITEMS_PER_LOAD);
  const [filteredItems, setFilteredItems] = useState<T[]>(items);

  useEffect(() => {
    if (items) {
      let filtered = items;

      if (searchQuery) {
        filtered = filtered.filter((item) => renderSearchKey(item).toLowerCase().includes(searchQuery.toLowerCase()));
      }

      if (subsetFilter && subsets) {
        filtered = filtered.filter((item) => subsetFilter(subsets, item));
      }

      setFilteredItems(filtered);
    } else {
      setFilteredItems(items);
    }
  }, [searchQuery, subsets, items]);

  const visibleItems = filteredItems?.slice(0, loadedCount);

  const handlePrev = () => {
    if (APPS_PER_PAGE > apps.length) {
      return;
    }
    setPage((prev) => (prev - APPS_PER_PAGE < 0 ? 0 : prev - APPS_PER_PAGE));
  };

  const handleNext = () => {
    if (APPS_PER_PAGE > apps.length) {
      return;
    }
    setPage((prev) =>
      prev + APPS_PER_PAGE > apps.length - APPS_PER_PAGE ? apps.length - APPS_PER_PAGE : prev + APPS_PER_PAGE,
    );
  };

  const handleLoadMore = () => {
    setLoadedCount(items.length);
  };

  const handleSelect = (option: T) => {
    onMultipleSelectItem(option);
  };

  const disabledPrevArrow = page === 0;
  const disabledNextArrow = page === apps.length - APPS_PER_PAGE || apps.length < APPS_PER_PAGE;

  const showLoadMoreBtn =
    filteredItems && filteredItems.length > ITEMS_PER_LOAD && visibleItems && visibleItems.length <= ITEMS_PER_LOAD;

  const visibleApps = apps?.slice(page, page + APPS_PER_PAGE);
  const hasAppsOptions = visibleApps?.length > 0 && type && doesInteractionSupportMultipleTargets(type);
  const renderIcons = (item: T) => {
    return visibleApps.map((app) => {
      const hasTitle = item.app_ids.includes(app.id);

      return (
        <td key={app.id} className={bem('app-name')}>
          <Icon name={hasTitle ? 'checkMark' : 'crossMark'} className="icon" />
        </td>
      );
    });
  };

  const onSubsetCheckboxChange = useCallback(
    (checked: boolean, value: S) => {
      if (!onSubsetsChanged) return;

      const newSubsets = new Set(subsets);

      if (checked) {
        newSubsets.add(value);
      } else {
        newSubsets.delete(value);
      }

      onSubsetsChanged(newSubsets);
    },
    [subsets, onSubsetsChanged],
  );

  return (
    <div ref={tableOptionRef} className={bem('', { visible: isOpen })}>
      {subsets && subsetOptions && (
        <div className={bem('subsets')}>
          {subsetOptions.map(({ name, label, className = '' }) => (
            <InputCheckbox
              key={name}
              value={name}
              className={`${className} ${bem('subset-checkbox', { checked: subsets.has(name) })}`.trim()}
              checked={subsets.has(name)}
              onChange={onSubsetCheckboxChange}
            >
              {label}
            </InputCheckbox>
          ))}
        </div>
      )}
      <table className={bem('table')}>
        <thead className={bem('header')}>
          <tr>
            <th className={bem('title')}>{title}</th>
            {hasAppsOptions && (
              <>
                <th className={bem('control')}>
                  <Icon
                    name="leftArrow"
                    className={bem('arrow', disabledPrevArrow ? 'disabled' : '')}
                    onClick={handlePrev}
                  />
                </th>
                {visibleApps?.map((app) => (
                  <th key={app.id} className={bem('app-name')}>
                    {app.title} <br /> {app.platform}
                  </th>
                ))}
                <th className={bem('control')}>
                  <Icon
                    name="rightArrow"
                    className={bem('arrow', disabledNextArrow ? 'disabled' : '')}
                    onClick={handleNext}
                  />
                </th>
              </>
            )}
          </tr>
        </thead>
      </table>
      {filteredItems && filteredItems.length > 0 ? (
        <div className={bem('scrollable-table')}>
          <table className={bem('table')}>
            <tbody>
              {visibleItems?.map((item) => (
                <tr
                  key={item.key}
                  className={`${bem('item-row', isMultipleOptionDisabled(item) ? 'disabled' : '')}`}
                  onClick={() => !isMultipleOptionDisabled(item) && handleSelect(item)}
                >
                  <td className={bem('item')}>{renderLabel(item)}</td>
                  {hasAppsOptions && (
                    <>
                      <td className={bem('control')} />
                      {renderIcons(item)}
                      <td className={bem('control')} />
                    </>
                  )}
                </tr>
              ))}
            </tbody>
            {showLoadMoreBtn && (
              <tfoot>
                <tr>
                  <td colSpan={6} className={bem('load-more')} onClick={handleLoadMore}>
                    Load More...
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      ) : (
        <tbody>
          <tr>
            <td colSpan={6} className="no-option">
              No Options
            </td>
          </tr>
        </tbody>
      )}
    </div>
  );
};

type EventAppItem = SimpleEventItem & {
  app_ids: string[];
};

export type EventTableOptionProps = Omit<TableOptionProps<EventAppItem, never>, 'items' | 'renderLabel' | 'title'> & {
  multipleEvents?: AppEventList[];
  eventsTitleList?: SimpleEventItem[];
};

export const EventTableOption: React.FC<EventTableOptionProps> = ({
  multipleEvents = [],
  eventsTitleList = [],
  ...rest
}) => {
  const eventAppsItems: EventAppItem[] = useMemo(() => {
    const appsForEvents = new Map();

    for (const { app_id, items } of multipleEvents) {
      for (const { label, archived } of items) {
        if (!archived) {
          let appsForEvent = appsForEvents.get(label);

          if (!appsForEvent) {
            appsForEvent = [];
            appsForEvents.set(label, appsForEvent);
          }

          appsForEvent.push(app_id);
        }
      }
    }

    return eventsTitleList
      .map((item) => ({ app_ids: appsForEvents.get(item.label) || [], ...item }))
      .filter(({ app_ids }) => app_ids.length > 0);
  }, [eventsTitleList, multipleEvents]);

  const renderLabel = useCallback(({ label }: EventAppItem) => eventNameToHuman(label), []);

  return <TableOption title="Event Name" items={eventAppsItems} renderLabel={renderLabel} {...rest} />;
};
